import '../styles/main.scss';

import Isotope from 'isotope-layout';

jQuery(function($) {

    var Main = (function ($,window,document) {
        var that;

        var Main = function () {
            //public properties shared across instances
            that = this;
            that.name = 'Main';
        };

        // public static
        Main.staticMethod = function () {
        };

        Main.prototype = {
            init:function()
            {
                that.initProjectFilters();
                that.initContactBox();
            },
            initProjectFilters:function() {
                const elem = document.querySelector('.akyos-portfolio-container');
                if (elem) {
                    let akysotope = new Isotope( elem, {
                        itemSelector: '.akyos-portfolio',
                        masonry: {
                            gutter: 25
                        }
                    });
    
                    $('.akyos-portfolio-container-filters .aky-filter').click(function (){
                        let cat = $(this).attr('data-cat');
                        let title = $(this).attr('data-title');
                        $('.placeholder-portfolio').text(title);
                        akysotope.isotope({filter : cat});
                    });
                }
            },
            initContactBox:function() {
                jQuery('.contact-wrapper').click(function() {
                    if(jQuery('.contact-box').hasClass('hover-box')) {
                        jQuery('.contact-box').removeClass('hover-box');
                    } else {
                        jQuery('.contact-box').addClass('hover-box');
                    }
                })
                jQuery('.contact-content').click(function(e) {
                    e.stopPropagation();
                })
            },
        };
        return Main;
    })($,window,document);

    window.main = new Main();
    $(document).ready(function () {
        window.main.init();
    });

});